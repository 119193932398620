<template>
    <div>
        <base-header class="pb-6">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <route-breadcrumb></route-breadcrumb>
            </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
        </div>
        </base-header>
        <div class="container-fluid mt--6">

            <div class="row">
                <div class="col">
                    <div class="card" v-loading="loading">
                        <div class="m-3 pl-3">
                            <base-checkbox v-model="showChecked">{{ $t('message.show_checked_rides') }}</base-checkbox>
                        </div>

                        <div v-if="pageCount > 1" class="ml-3 pb-4 d-md-flex justify-content-between align-items-center hasArchiveCheckbox">
                            <el-pagination
                                background
                                :current-page="currentPage"
                                @current-change="onCurrentChange"
                                :total="items.length"
                                :page-count="pageCount"
                                :page-size="pageSize"
                                layout="prev, pager, next">
                            </el-pagination>
                            <el-select
                            class="select-primary mr-2"
                            v-model="pageSize" filterable>
                                <el-option class="select-primary ml-1"
                                    value="10"
                                    label="10"
                                    key="10">
                                </el-option>
                                <el-option class="select-primary ml-1"
                                    value="50"
                                    label="50"
                                    key="50">
                                </el-option>
                                <el-option class="select-primary ml-1"
                                    :value="listLength"
                                    :label="$t('message.page_size_all', { allCount: listLength })"
                                    key="100">
                                </el-option>
                            </el-select>
                        </div>

                        <el-table class="table-responsive table-flush logistic-table"
                            header-row-class-name="thead-light"
                            :data="paginatedItems">
                            <el-table-column :label="$t('message.project')"
                                prop="project.name"
                                width="400"
                                sortable>
                                <template v-slot="{row}">
                                    <div class="d-flex">
                                        <router-link :to="`/project/${row.project.id}`">{{row.project.name}}</router-link>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.projectId')"
                                prop="project.key"
                                sortable
                                width="130">
                            </el-table-column>
                            <el-table-column :label="$t('message.ladeTermin')"
                                prop="load"
                                sortable
                                width="130">
                                <template v-slot="{row}">
                                    <div v-for="(value, index) in getLoadingActionAndDate(row)" :key="index">
                                        {{ value }}
                                    </div>
                                    <base-button
                                    type="link"
                                    size="sm"
                                    @click="showRides(row)"
                                    >
                                        {{ row.vehicle.type }}
                                    </base-button>
                                </template>
                            </el-table-column>
                            <el-table-column width="150" :label="$t('message.action')">
                                <template v-slot="{row}">
                                    <div v-for="(value, index) in getLoadingActionAndDate(row)" :key="index">
                                        {{ index }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.approvalDate')"
                                prop="checked"
                                width="150"
                                sortable="custom"
                                >
                                <template v-slot="{row}">
                                    <div class="input-group">
                                        <flat-picker
                                            autocomplete="off"
                                            class="form-control datepicker"
                                            placeholder=""
                                            :config="dateConfig"
                                            v-model="row.date_released"
                                            @input="setReleaseDate(row)"
                                            />
                                        <div class="input-group-append">
                                            <button class="btn-inner--icon calendar" type="button" data-toggle>
                                                <i class="far fa-calendar"/>
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="150" label="Rückeinlagerung">
                                <template v-slot="{row}">
                                    <div class="category-sums" v-for="(value, index) in getRestorageValues(row)" :key="index">
                                        <span>{{ index }}</span><span>{{ value }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.state')" width="230">
                                <template v-slot="{row}">
                                    <div class="category-sums" v-for="(value, index) in row.category_sums" :key="index"
                                        @click="$router.push({
                                                            path: `/project/${row.project_id}/picking-list`,
                                                            query: {'cat_name': value.cat_name}
                                                            })"
                                    >
                                        <span>{{ value.cat_name }}</span><span :class=value.classname>{{ value.done }}/{{ value.all }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="150" :label="$t('message.title_check')">
                                <template v-slot="{row}">
                                    <span v-if="row.project.komm_checked != null">
                                        <base-button size="sm" type="primary" @click="$router.push({ path: `/project/${row.project.id}/picking-list`})">
                                            {{ $t('message.list_checked') }}
                                        </base-button>
                                        <div class="small">{{ getUser(row.project.komm_checked_user) }}, {{ row.project.komm_checked | date }}</div>
                                    </span>
                                    <span v-if="!row.project.komm_checked">
                                        <base-button size="sm" type="light" @click="$router.push({ path: `/project/${row.project.id}/picking-list`})">
                                        {{ $t('message.list_unchecked') }}
                                        </base-button>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="150" :label="$t('message.ride_complete')">
                                <template v-slot="{row}">
                                    <base-button v-if="row.checked == 0" size="sm" type="secondary" @click="rideChecked(row)">
                                        CHECK
                                    </base-button>
                                    <base-button v-if="row.checked == 1" size="sm" type="light" @click="rideChecked(row)">
                                        UNCHECK
                                    </base-button>
                                </template>
                            </el-table-column>
                        </el-table>

                        <div v-if="pageCount > 1" class="card-footer py-4 d-md-flex justify-content-between align-items-center hasArchiveCheckbox">
                            <el-pagination
                                background
                                :current-page="currentPage"
                                @current-change="onCurrentChange"
                                :total="items.length"
                                :page-count="pageCount"
                                :page-size="pageSize"
                                layout="prev, pager, next">
                            </el-pagination>
                            <el-select
                                class="select-primary mr-2"
                                v-model="pageSize" filterable>
                                <el-option class="select-primary ml-1"
                                    value="10"
                                    label="10"
                                    key="10">
                                </el-option>
                                <el-option class="select-primary ml-1"
                                    value="50"
                                    label="50"
                                    key="50">
                                </el-option>
                                <el-option class="select-primary ml-1"
                                    :value="listLength"
                                    :label="$t('message.page_size_all', { allCount: listLength })"
                                    key="100">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal :show.sync="showRidesModal">
            <h6 slot="header" class="modal-title">
                Ladetermine
            </h6>
            <p class="text-bold">{{ ridesList.title }}</p>
            <div class="rides-list" v-for="(value, index) in ridesList.rides" :key="index">
                <div class="ride-name">{{ index }}:</div><div> {{ value | datetime }}</div>
            </div>
            <div class="rides-list">
                <div class="ride-name">Fahrer:</div><div> {{ ridesList.driver }}</div>
            </div>
            <div class="rides-list">
                <div class="ride-name">Kennzeichen:</div><div> {{ ridesList.license }}</div>
            </div>
            <div class="rides-list">
                <div class="ride-name">Benmerkung:</div><div> {{ ridesList.notice }}</div>
            </div>
            <template slot="footer">
                <base-button
                type="link"
                class="ml-auto"
                @click="showRidesModal = false"
                >{{ $t("message.close") }}</base-button
                >
            </template>
        </modal>
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { formatDate } from '@/util/data'
  import sleep from "@/util/sleep";
  import "flatpickr/dist/flatpickr.css";
  import FlatPicker from "vue-flatpickr-component";

  export default {
    name: 'logisticView',
    components: {
        FlatPicker
    },
    data() {
      return {
        loading: false,
        pageSize: 10,
        showRidesModal: false,
        showChecked: false,
        ridesList: [],
        preloaded: [],
        dateConfig: {
            wrap: true,
            allowInput: true,
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "d.m.Y"
        }
      }
    },
    computed: {
        ...mapGetters([
        "users",
        "allProjects",
        ]),
        items() {
            let result = this.preloaded;

        if (!this.showChecked) {
            result = result.filter((item) => item.checked !== 1);
        }

        return result
        },
        listLength() {
            if (this.pageSize === this.items.length - 1) {
                this.$store.commit(
                    "SET_DATA_INPUT_PAGE_SIZE",
                    this.items.length
                );
            }
            return this.items.length;
        },
        pageCount () {
            return Math.ceil(this.items.length / this.pageSize);
        },
        paginatedItems () {
            const start = (this.currentPage - 1) * this.pageSize
            const end = start + this.pageSize
            return this.items.slice(start, end)
        },
        currentPage: {
            get() {
                return this.$store.getters.dataInputCurrentPage;
            },
            set(val) {
                this.$store.commit("SET_DATA_INPUT_CURRENT_PAGE", val);
            },
        },
        pageSize: {
            get() {
                return this.$store.getters.dataInputPageSize;
            },
            set(val) {
                this.$store.commit("SET_DATA_INPUT_PAGE_SIZE", val);
            },
        },
    },
    methods: {
        ...mapActions([
            "getLogisticPositions",
            "getLogisticRides"
        ]),
        async load() {
            this.loading = true;
            this.preloaded = await this.loadPositions();
            this.loading = false;
        },
        async confirm({ callback, confirmMessage, successMessage }) {
            try {
                await this.$confirm(
                this.$t(confirmMessage),
                this.$t("message.warning"),
                {
                    confirmButtonText: this.$t("message.yes"),
                    cancelButtonText: this.$t("message.no"),
                    type: "warning",
                }
                );
            } catch (error) {
                return this.$message({
                type: "info",
                message: "Canceled",
                });
            }

            this.isLoading = true;
            try {
                await callback();
            } catch (error) {
                this.isLoading = false;
                return;
            }
            await sleep(100);

            this.isLoading = false;
            if (successMessage) {
                this.$message({ type: "success", message: successMessage });
            }
        },
        async rideChecked(ride) {
            let checkedState = ride.checked == 0 ? 1 : 0;
            let confirm = ride.checked == 0 ? 'message.confirm_ride_checked' : 'message.confirm_ride_unchecked';
            
            this.confirm({
                callback: async () => {
                    await this.$store.dispatch("updateRide", { id: ride.id, data: { checked: checkedState }, })
                        .then(() => { ride.checked = checkedState })
                },
                confirmMessage: confirm,
                successMessage: this.$t("message.status_changed_success"),
            });
        },
        setReleaseDate(item) {
            this.$store.dispatch('updateRide', {
                id: item.id,
                data: {
                    date_released: item.date_released
                }
            });

        },
        getUser(id) {
            return this.users.filter(u => u.id == id)[0].name
        },
        getLoadingActionAndDate(pos) {
            let materials = pos.positions.map(m => {return m.materials});
            let state_load = materials[0].filter(m => m.state_lade == 'OFFEN').length;
            let state_restorage = materials[0].filter(m => m.state_rueck == 'OFFEN').length;

            const ridesWithDate = {};

            if (state_load > 0) {
                let date_load = pos.load !== null ? formatDate(new Date(pos.load)) : '';
                ridesWithDate['Laden'] = date_load
            }
            if (state_restorage > 0) {
                let date_restorage = pos.unload_back !== null ? formatDate(new Date(pos.unload_back)) : '';
                ridesWithDate['Rückeinlagerung'] = date_restorage
            }

            return ridesWithDate;
        },
        getRestorageValues(pos) {
            let materials = pos.positions.map(m => {return m.materials});
            let use = [];
            for(const item of materials) {
                item.filter(i => { if (i.rueck_result_customer !== null) { use.push(i.rueck_result_customer) } });
            }
            
            let further_use = {};
            use.filter(value => {
                if (further_use[value] <= 1) {
                    further_use[value] += 1;
                } else {
                    further_use[value] = 1;
                }                
            });

            return further_use;
        },
        showRides(row) {
            this.showRidesModal = true;
            this.ridesList = 
            {
                'title': row.address.name +', '+ row.address.city,
                'rides': {
                    'Laden': row.load,
                    'Abfahrt': row.departure,
                    'Abladen vor Ort': row.unload,
                    'Montagebeginn': row.assembly,
                    'Aufladen vor Ort': row.load_back,
                    'Abladen bei APA': row.unload_back
                },
                'driver': row.driver,
                'license': row.license,
                'notice': row.notice
            };
        },
        onCurrentChange(pageNumber) {
            this.currentPage = pageNumber;
        },
        getCategorySums(rides) {
            let newRides = []
            let categories = ['Verbrauchsmaterial',
                              'APA Lagerware',
                              'Einkaufsware',
                              'Produktionsware',
                              'Werkzeug']

            for(let ride of rides) {
                newRides[ride.project_id] = []
                ride.category_sums = [];
                let collected_all = []
                let collected_done = []

                ride.project.pos_mat_cat.filter(p => {
                                            if (p.materials) {
                                                p.materials.filter(d => {
                                                    if (d.state_komm == 'KOMMISSIONIERT') {
                                                        if(collected_done[d.category.name]) {
                                                            collected_done[d.category.name] = collected_done[d.category.name] + 1
                                                        } else {
                                                            collected_done[d.category.name] = 0
                                                            collected_done[d.category.name] = collected_done[d.category.name] + 1
                                                        }
                                                    }
                                                })
                                                p.materials.map(m => m.category)
                                                            .filter(m => {
                                                                if(collected_all[m.name]) {
                                                                    collected_all[m.name] = collected_all[m.name] + 1
                                                                } else {
                                                                    collected_all[m.name] = 0
                                                                    collected_all[m.name] = collected_all[m.name] + 1
                                                                }
                                                            })
                                            }
                                        })

                categories.forEach((item) => {
                    let done = typeof collected_done[item] === 'undefined' ? 0 : collected_done[item]
                    let all = typeof collected_all[item] === 'undefined' ? 0 : collected_all[item]
                    let classname = 'red'
                    if(done === all) {
                        classname = 'green'
                    }

                    ride.category_sums.push({'cat_name': item, 'done': done, 'all': all, 'classname': classname})
                })
            }

        },
        async loadPositions() {
            let logistic = await this.getLogisticPositions();
            let pos = logistic.filter(p => p.project.state == 'NEW')
                                .filter(p => {
                                    if(p.materials) {
                                        let o = p.materials;
                                        return o.filter(pos => pos.state_rueck != 'ZURUECK').length > 0;
                                    }
                                })

            const ride_ids = pos.map(r => r.ride_id).filter((value, index, array) => array.indexOf(value) === index && value !== null);

            let ridesPositions = [];

            for(const item of ride_ids)  {
                let rides = await this.getLogisticRides(item)
                let ride = rides[0]
                let posRide = pos.filter(r => r.ride_id == ride.id)
                ride.positions = posRide

                ridesPositions.push(ride)
            }

            let logisticRides = ridesPositions.sort((a, b) => {
                                    return new Date(a.load) - new Date(b.load)
                                })

            this.getCategorySums(logisticRides)
            
            return logisticRides;
        }
    },
    mounted(){
        this.load();
    }
  }
</script>
<style>
.rides-list {
    display: flex;
    flex-direction: row;
}
.ride-name {
    flex-basis: 20%;
    margin-bottom: 10px;
}
.logistic-table td {
    vertical-align: top;
}
.logistic-table input.datepicker {
    outline: none;
    border: none;
}
.logistic-table button.calendar {
    outline: none;
    border: none;
    background: none;
    padding-right: 0;
    padding-left: 0.5em;
}
.category-sums {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}
.category-sums:hover {
    font-weight: 600;
    color: #9f2f37;
}
.green {
    color: green;
}
.red {
    color: #9f2f37
}
</style>